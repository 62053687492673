import React, {useContext} from 'react';
import {Link} from 'gatsby';
import {navigate} from 'gatsby-link';
import Layout from '../../components/layout/layout';
import {PageHero} from '../../components/page-hero';
import Section from '../../components/section';
import Button from '../../components/button';
import hexomaticGif from '../../images/Hexomatic_Translation.gif';
import {UserContext} from '../../helpers/contexts/user-context';
import './styles.scss';

//!don't forget meta tags
const toolsList = [
  {
    title: 'Text to Speech',
    subTitle: 'Enables text to be converted into speech sounds imitative of the human voice via Google Text-to-Speech.',
    src: require('../../images/tools/text-to-speech.png'),
    path: '/text-to-speech',
  },
  {
    title: 'Tech Stack Analyzer',
    subTitle:
      'Discover the tech stack used on any page including what type of technology and third-party services are currently in use.',
    src: require('../../images/tools/tech-stack-analyzer.png'),
    path: '/tech-stack-analyzer',
  },
  {
    title: 'WHOIS Domain Lookup',
    subTitle: 'Discover WHOIS details for any given domain name',
    src: require('../../images/tools/whois-domain-lookup.png'),
    path: '/whois-domain-lookup',
  },
  {
    title: 'Grammar & spelling page audit',
    subTitle: 'Check any web page for spelling and grammar mistakes',
    src: require('../../images/tools/grammar.png'),
    path: '/grammar-spell-checker',
  },
  {
    title: 'Accessibility audit',
    subTitle: 'Check any web page for accessibility issues',
    src: require('../../images/tools/accessibility.png'),
    path: '/accessibility-audit',
  },
  {
    title: 'Screenshot capture',
    subTitle: 'Capture website screenshots for any device screen size online',
    src: require('../../images/tools/screenshot.png'),
    path: '/screenshot-capture',
  },
  {
    title: 'Content analysis',
    subTitle: 'Analyze any page for on-page content metrics',
    src: require('../../images/tools/content-analysis.png'),
    path: '/word-count',
  },
  {
    title: 'QR code generator',
    subTitle: 'Create your own QR code from any URL, text, number and more',
    src: require('../../images/tools/qr-generator.png'),
    path: '/qr-code-generator',
  },
];

const GetStartedBtn = () => {
  const {user} = useContext(UserContext);
  // setToolNameHistory(JSON.stringify({label: 'All', value: ''}));

  return (
    <Button onClick={() => navigate(user && user.id ? '/registration/' : '/pricing/')}>
      Get started in minutes
    </Button>
  );
};
const ToolsList = () => {
  return (
    <Layout>
      <PageHero
        title="1-Click tools for growth hackers"
        subtitle="Find contact details, perform tech stack analysis or tap into AI services with our easy to use growth hacking tools, powered by Hexomatic."
        id="seo_home_area_tools"
      />
      <Section>
        <>
          <div className="tools-list row mx-2 w-100">
            <div className="tools-list row mx-2 w-100">
              <Link to="/tools/history" className="history-link ml-auto pr-5">
                See history
              </Link>
            </div>
            {toolsList.map(tool => (
              <div
                key={tool.path}
                className="col-12 col-md-6 col-lg-4 tool my-2 mx-2 cursor-pointer tools-card"
                onClick={() => navigate(`/tools${tool.path}/`)}
              >
                <div className="img-wrapper">
                  <img src={tool.src} alt="hexomatic-tool" />
                </div>
                <div className="title">{tool.title}</div>
                <div className="sub-title">{tool.subTitle}</div>
              </div>
            ))}
          </div>
          <div className="tools-info row mx-2 w-100 my-5 py-3">
            <div className="col-12 col-lg-6 p-2">
              <h4>Automate &amp; scale time consuming tasks like never before</h4>
              <div>Hexomatic is the no-code, point and click work automation platform.</div>
              <div>
                Harness the internet as your own data source, build your own scraping bots and leverage ready made
                automations to delegate time consuming tasks and scale your business.
              </div>
              <div>No coding or PhD in programming required.</div>
              <div className="mt-2">
                {' '}
                <GetStartedBtn />
              </div>
            </div>
            <div className="col-12 col-lg-6 p-2 text-center">
              <img className="hexomatic-gif" src={hexomaticGif} alt="workflow" />
            </div>
          </div>
        </>
      </Section>
    </Layout>
  );
};
export default ToolsList;
